<template>
    <el-main>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="注册账号">
                <el-input size="small" v-model="form.u_mobile"></el-input>
            </el-form-item>
            <el-form-item label="技工姓名">
                <el-input size="small" v-model="form.artisan_name"></el-input>
            </el-form-item>
            <el-form-item label="申请时间">
                <el-date-picker v-model="time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期"
                    end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="联系电话">
                <el-input size="small" v-model="form.mobile"></el-input>
            </el-form-item>
            <el-form-item label="提现单号">
                <el-input size="small" v-model="form.withdraw_no"></el-input>
            </el-form-item>
            <el-form-item label="审核状态">
                <el-select size="small" v-model="form.status" placeholder="请选择">
                    <el-option v-for="item in status" :key="item.val" :label="item.label" :value="item.val"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label=" " label-width="25px">
                <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
                <el-button size="small" @click="exportOrder">导出</el-button>
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" row-key="id" default-expand-all :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="name" label="申请时间" align="center">
                <template slot-scope="scope">{{ getDateformat(scope.row.create_time) }}</template>
            </el-table-column>
            <el-table-column prop="withdraw_no" label="提现单号" align="center"> </el-table-column>
            <el-table-column prop="u_mobile" label="关联用户注册账号" align="center"></el-table-column>
            <el-table-column prop="artisan_name" label="技工姓名" align="center"></el-table-column>
            <el-table-column label="联系电话" align="center" prop="mobile"></el-table-column>
            <el-table-column label="提现费用" align="center">
                <template slot-scope="scope">￥{{ scope.row.withdraw_amount }}</template>
            </el-table-column>
            <el-table-column label="打款金额" align="center">
                <template slot-scope="scope">￥{{ scope.row.remit_amount }}</template>
            </el-table-column>
            <el-table-column label="审核状态" align="center">
                <template slot-scope="scope">{{ status.find(v => v.val == scope.row.status).label }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button @click="handleVoucher(scope.row.voucher)" type="text" size="small"
                        v-if="scope.row.status == 2">打款凭证</el-button>
                    <el-button @click="handleAudit(scope.row)" type="text" size="small"
                        v-if="scope.row.status == 1">审核</el-button>
                    <el-popover placement="top-start" width="200" trigger="click" :content="scope.row.remark">
                        <el-button type="text" size="small" slot="reference" v-if="scope.row.status == -1">驳回原因</el-button>
                    </el-popover>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
        <el-dialog title="审核" :visible.sync="dialogVisible" width="40%">
            <el-form label-width="160px" :model="audit_form" :rules="audit_rules" ref="audit_form">
                <el-form-item label="请选择审核结果：">
                    <el-radio-group v-model="audit_form.status">
                        <el-radio :label="2">通过</el-radio>
                        <el-radio :label="-1">驳回</el-radio>
                    </el-radio-group>
                </el-form-item>
                <block v-if="audit_form.status == 2">
                    <el-form-item label="打款类型：">
                        <div>{{ type.find(v => v.val == row.withdraw_type) ? type.find(v => v.val ==
                            row.withdraw_type).label : '' }}</div>
                    </el-form-item>
                    <block v-if="row.withdraw_type == 1">
                        <el-form-item label="授权ID：">
                            <div>{{ row.alipay_account }}</div>
                        </el-form-item>
                    </block>
                    <block v-if="row.withdraw_type == 2">
                        <el-form-item label="支付宝账号：">
                            <div>{{ row.alipay_account }}</div>
                        </el-form-item>
                    </block>
                    <block v-if="row.withdraw_type == 3">
                        <el-form-item label="开户行：">
                            <div>{{ row.bank_info }}</div>
                        </el-form-item>
                        <el-form-item label="银行卡号：">
                            <div>{{ row.bank_card }}</div>
                        </el-form-item>
                        <el-form-item label="持卡人：">
                            <div>{{ row.bank_name }}</div>
                        </el-form-item>
                    </block>
                    <el-form-item label="打款金额：" prop="remit_amount">
                        <el-input size="small" v-model="audit_form.remit_amount" placeholder="请输入打款金额"></el-input>
                    </el-form-item>
                    <el-form-item label="打款凭证：" v-if="row.withdraw_type == 3" prop="voucher">
                        <el-button @click="chooseImgFlag = true">上传凭证</el-button>
                        <div v-if="audit_form.voucher" style="margin-top:10px;">
                            <el-image style="width: 100px; height: 100px" :src="audit_form.voucher"
                                :preview-src-list="[audit_form.voucher]">
                            </el-image>
                        </div>
                    </el-form-item>
                </block>
                <el-form-item label="请填写驳回原因：" v-else>
                    <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入内容"
                        v-model="audit_form.remark">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmRemit">确 定</el-button>
            </span>
        </el-dialog>
        <UploadSource v-if="chooseImgFlag" @changeStatus="changeStatus" :dialogVisible="chooseImgFlag"
            @getSource="getImgRes" type="image" :maxSize="1024 * 1024"></UploadSource>
        <el-dialog :visible.sync="dialogVisible_image" width="30%" title="打款凭证">
            <el-image style="width: 200px; height: 200px;margin: 0 auto;display: block;" :src="voucher"
                :preview-src-list="[voucher]">
            </el-image>
        </el-dialog>
    </el-main>
</template>

<script>
import config from '@/util/config';
//const industryName = '/Gateway'; //行业版本接口名称（用于更换重复接口）
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import UploadSource from '@/components/uploadSource'
import { inspectPrice } from "@/util/verification"
export default {
    components: {
        Paging,
        UploadSource
    },
    data () {
        return {
            form: {
                page: 1,
                rows: 10,
                u_mobile: '',
                artisan_name: '',
                mobile: '',
                start_time: '',
                end_time: '',
                withdraw_no: '',
                status: ''
            },
            time: '',
            status: [
                {
                    val: -1,
                    label: '已驳回'
                },
                {
                    val: 1,
                    label: '待审核'
                },
                {
                    val: 2,
                    label: '审核通过'
                }
            ],
            list: [],
            total: 0,
            audit_form: {
                id: '',
                status: 2,
                remark: '',
                remit_amount: '',
                voucher: '',
            },
            dialogVisible: false,
            type: [
                {
                    val: 1,
                    label: "微信钱包"
                },
                {
                    val: 2,
                    label: "支付宝"
                },
                {
                    val: 3,
                    label: "银行卡"
                }
            ],
            row: '',
            audit_rules: {
                remit_amount: [
                    {
                        validator: (rule, value, callback) => {
                            if (!inspectPrice(value)) {
                                callback(new Error('请输入金额正确格式!'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur'
                    },
                    { required: true, message: '请输入打款金额', trigger: 'blur' },
                ],
                voucher: [{ required: true, message: '请上传打款凭证', trigger: 'change' },]
            },
            chooseImgFlag: false,
            dialogVisible_image: false,
            voucher: ''
        };
    },
    created () {
        this.getList();
    },
    methods: {
        getDateformat,
        updateData (val, status) {
            if (status == 0) {
                this.form.rows = val;
                this.getList();
            } else {
                this.form.page = val;
                this.getList();
            }
        },
        getList (page = 0) {
            if (page) {
                this.form.page = 1
            }
            if (this.time) {
                this.form.start_time = this.time[0] / 1000
                this.form.end_time = this.time[1] / 1000
            } else {
                this.form.start_time = ''
                this.form.end_time = ''
            }
            this.$axios
                .post(this.$api.repair.finance.artisanWithdrawList, this.form)
                .then(res => {
                    if (res.code == 0) {
                        let { total_number, list } = res.result
                        this.total = total_number
                        this.list = list
                    }
                });
        },
        cancelSearch () {
            this.form = {
                page: 1,
                rows: 10,
                u_mobile: '',
                artisan_name: '',
                mobile: '',
                start_time: '',
                end_time: '',
                withdraw_no: '',
                status: ''
            }
            this.getList()
        },
        handleAudit (row) {
            this.row = row
            this.audit_form = {
                id: row.id,
                status: 2,
                remark: '',
                remit_amount: '',
                voucher: '',
            }
            this.dialogVisible = true
        },
        changeStatus (val) {
            this.chooseImgFlag = val
        },
        getImgRes (imglist) {
            this.audit_form.voucher = imglist[0].path
        },
        confirmRemit () {
            this.$refs['audit_form'].validate((valid) => {
                if (valid) {
                    this.$axios
                        .post(this.$api.repair.finance.artisanWithdrawAudit, this.audit_form)
                        .then(res => {
                            if (res.code == 0) {
                                this.$message.success('审核成功')
                                this.dialogVisible = false
                                this.getList();
                            }
                        });
                } else {
                    return false;
                }
            });
        },
        handleVoucher (voucher) {
            this.voucher = voucher
            this.dialogVisible_image = true
        },
        exportOrder () {
            if (this.time) {
                this.form.start_time = this.time[0] / 1000
                this.form.end_time = this.time[1] / 1000
            } else {
                this.form.start_time = ''
                this.form.end_time = ''
            }
            let obj = {
                u_mobile: this.form.u_mobile,
                artisan_name: this.form.artisan_name,
                mobile: this.form.mobile,
                start_time: this.form.start_time,
                end_time: this.form.end_time,
                withdraw_no: this.form.withdraw_no,
                status: this.form.status,
            }
            this.$axios
                .post(this.$api.repair.finance.artisanWithdrawExport, obj)
                .then(res => {
                    if (res.code == 0) {
                        let path = config.baseurl + '/' + res.result;
                        let a = document.createElement('a');
                        a.href = path;
                        a.id = 'download';
                        document.body.appendChild(a);
                        a.click();
                        let aDom = document.getElementById('download');
                        document.body.removeChild(aDom);
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .el-table {
        flex: 1;

        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }

        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
    }

    .edit {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
        }

        i {
            font-size: 17px;
        }
    }

    .move .item {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-bottom: 20px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    // 这是点击后的旋转角度
    transform: rotate(0deg);
}

/deep/.el-table__expand-icon {
    .el-icon-arrow-right:before {
        //这是收起图标
        content: '\e723';
        font-size: 16px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    .el-icon-arrow-right:before {
        // 这是展开图标
        content: '\e722';
        font-size: 16px;
    }
}

.descriptions {
    font-size: 18px;
    font-weight: bold;
    padding: 30px 0;
    display: flex;

    .descriptions_item+.descriptions_item {
        margin-left: 50px;
    }
}
</style>
